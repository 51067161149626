<template lang="pug">
#DataTableSetting(class='py-4 addTableDialog pa-10' )
    .d-flex.justify-center
        h3(v-if='editMode == "ADD"') {{$t('GENERAL.ADD')}}－{{$t('TREESETTING.TS')}}
        h3(v-if='editMode == "EDIT"') {{$t('GENERAL.EDIT')}}－{{$t('TREESETTING.TS')}}
        h3(v-if='editMode == "COPY"') {{$t('GENERAL.COPY')}}－{{$t('TREESETTING.TS')}}
    v-card(flat)
        v-card-title.pb-0
            v-row(no-gutters)
                v-col(cols='6')
                    v-text-field.pt-1(:label="$i18n.t('TABLESETTING.NAME')" :rules="formRule.nameRules" v-model='editData.name' required)
        v-card-title.pt-0
            v-row(no-gutters).align-center.justify-space-between
                v-col(cols="3" md="3" )
                    v-btn.pl-0(@click='onAddOption()' width='120' height='32' color='green' plain border-0 class='d-flex justify-start')
                        v-icon.mr-1(size='14') icon-add
                        span {{$t('TREESETTING.ADD')}}
                v-col(cols="3").d-flex.justify-end
                    v-btn.pr-0(@click='open_all = !open_all' width='100' height='32' :color='!open_all? "blue":"red"' plain border-0 )
                        v-icon.mr-1(size='14') 
                        span {{ open_all ? $t('TREESETTING.CLOSE_ALL'):$t('TREESETTING.OPEN_ALL')}}
        v-form(ref='form' class='border' @submit.prevent).order-box
            v-card-text(cols="12" md="12" class='pa-0 align-items-end')
                div( class="ma-2 text-left text-no-wrap rounded-lg")
                    v-row(no-gutters)
                        v-treeview(:items='editData.value' item-key='id' item-text='label' :open='open' :open.sync='open')
                            template(v-slot:append='{ item }')
                                //- v-hover
                                //-     template(v-slot:default="{ hover }")
                                //-         div(v-show='!hover')
                                //-             v-btn.pa-0.ml-5(@click='onAddOption()' icon height='32' color='green' plain border-0 )
                                //-                 v-icon(size='14') icon-add
                                //-             v-btn.pa-0(@click='onDeleteOption()' icon height='32' color='red' plain border-0 )
                                //-                 v-icon(size='14') icon-remove
                            template(v-slot:label='{ item }')
                                //- v-text-field(v-model='item.name' style='width:150px;')
                                v-hover
                                    template(v-slot:default="{ hover }")
                                        v-row.align-center.justify-space-between
                                            v-col(cols='auto')
                                                v-text-field(:ref="'text-field-'+item.id" v-model='item.label' style='width:150px;' :rules='formRule.nameRules')
                                            v-col(cols='auto')
                                                div(v-show='hover')
                                                    v-btn.pa-0.ml-5(v-if='item.layer<3' @click='onAddSubOption(item)' icon height='32' color='green' plain border-0 )
                                                        v-icon(size='14') icon-add
                                                    v-btn.pa-0(@click='onDeleteOption(item)' icon height='32' color='red' plain border-0 )
                                                        v-icon(size='14') icon-remove
        v-card-actions(class='pa-0')
            v-row.mt-4
                v-col.pr-2.pl-0(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed='depressed' dark='dark') {{$t('GENERAL.CANCEL')}}
                v-col.pl-2.pr-0(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark') 
                        v-progress-circular(v-if='loading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.CONFIRM')}}    
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js' 
    // list

    // service

    export default Vue.extend({
        components: {
            errorDialog,
        },
        props: {
            treeSelectData: {
                type: Object,
                required: true,
            },
            editMode: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                open_all:false,
                delete_index:[],
                max_key:null,
                open:[],
                editData:[],
                // list 

                // dialog
                errorDialog: false,
                errorMessage: '',

                // data
                user: JSON.parse(window.localStorage.getItem('user')),
                loading: false,
                contact_loading: false,
                org_loading: false,
                product_loading: false,
                case_loading: false,
                tab_items:['Contact', 'Opportunity', 'Organization','Case'],
                filterSet:[],
                visibility: true,
                fields: [],
                menu:[],
                havecampaignlist:false,
                haveseglist:false,
                search_org:[],
                currentIndex: null,
                search_contact:[],
                search_product: [],
                search_case: [],
                contactData:[],
                productData: [],
                caseData: [],
                formRule: {
                    bolRule: [
                        (v) =>  v.toString().length>0  || i18n.t('RULE.RULE_R'),
                    ],
                    requireRule: [
                        (v) =>  v.length>0 || i18n.t('RULE.RULE_R'),
                    ],
                    nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_NAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    numRules: [
                        (v) => (v === 0 || !!v) || i18n.t('RULE.RULE_R_NUM')
                    ],
                    dateRule: [(v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')],
                },
                originData: JSON.parse(JSON.stringify(this.treeSelectData))
            }
        },
        async created(){
            this.max_key = this.treeSelectData.max_key;
            this.editData = this.treeSelectData;
        },
        methods: {
            // 取得index array
            getIndex(array,item,lastIndex){
                for(let i in array){
                    let index = [];
                    if(lastIndex.length>0){
                        index = lastIndex;
                    }
                    if(array[i]==item){
                        index.push(i);
                        this.delete_index = index;
                        return ;
                    }else{
                        if(Object.keys(array[i]).includes('children')){
                            index.push(i);
                            this.getIndex(array[i].children,item,index);
                        }
                    }
                }
            },
            // 生成CF用的options_set
            optionForCF(data,optins){
                data.map((el) => {
                        if(el.children.length==0){
                            Vue.set(optins,el.id,el.customLabel)
                        }else{
                            this.optionForCF(el.children,optins)
                        }
                });
                return optins;
            },
            // 生成選擇後顯示用的名稱
            setShowLabel(data,name){
                return data.map((el) => {
                        let customName = name==""? el.label : name+" / "+el.label;
                        return {
                        ...el,
                        children: this.setShowLabel(el.children,customName),
                        customLabel: customName
                        };
                });
            },
            // 刪除選項
            removeIItem(data,id){
                return  data.filter((el)=>{return el.id != id})
                .map((el) => {
                    if (el.children.length>0) {
                        return {
                        ...el,
                        children: this.removeIItem(el.children,id),
                        };
                    }
                    return el;
                });
            },
            async onDeleteOption(item){
                this.editData.value = this.removeIItem(this.editData.value,item.id);
            },
            onAddOption(){
                this.max_key = this.max_key + 1;
                let newEditData = JSON.parse(JSON.stringify(this.editData.value))
                if(this.editMode == "EDIT"){
                    newEditData.push({id:this.treeSelectData.id+"_"+this.max_key.toString(),label:"",layer:1,children:[]});
                }else{
                    newEditData.push({id:this.max_key.toString(),label:"",layer:1,children:[]});
                }
                this.editData.value = newEditData;
                let focus = `text-field-${this.max_key}`;
                setTimeout(()=>{
                    this.$refs[focus].focus();
                },0)
            },
            async onAddSubOption(data){
                this.max_key = this.max_key + 1;
                if(Object.keys(data).includes('children')){
                    if(this.editMode == "EDIT"){
                        data.children.push({id:this.treeSelectData.id+"_"+this.max_key.toString(),label:"",layer:1,children:[]});
                    }else{
                        data.children.push({id:this.max_key.toString(),label:"",layer:data.layer+1,children:[]})
                    }
                }else{
                    Vue.set(data,"children", [{id:this.max_key.toString(),label:"",layer:data.layer+1,children:[]}])
                }
                if(!this.open.includes(data.id)){
                    this.open.push(data.id);
                }
                let focus = `text-field-${this.max_key}`;
                setTimeout(()=>{
                    this.$refs[focus].focus();
                },0)
            },
            // dialog
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog(){
                this.errorDialog = false;
            },
            onConfirm() {
                if(this.editData.value.length == 0){
                    this.showErrorDialog(i18n.t("TREESETTING.NO_OPTION"));
                    return;
                }
                this.setShowLabel(this.editData.value,"")
                if(this.$refs.form.validate()){
                    this.editData.value = this.setShowLabel(this.editData.value,"");
                    this.editData.max_key = this.max_key;
                    this.editData.option_set = this.optionForCF(this.editData.value,{});
                    this.$emit('emitEditDialog', true, this.editData,this.editMode);
                }
            },
            onCancel(){
                this.$emit('emitEditDialog', false);
            },
        },
        watch:{
            "open_all" : function(){
                if(this.open_all){
                    for(let i = 0;i<=this.max_key;i++){
                        if(this.editMode == "EDIT"){
                            this.open.push(this.treeSelectData.id+"_"+i);
                        }else{
                            this.open.push(i);
                        }
                    }
                }
                else{
                    this.open = [];
                }
            }
        },
    });
</script>