<template lang="pug">
v-card.textarea-style
    v-card-title.d-flex.justify-space-between
        h3(v-if="stageType.type!='CASE'") {{$t('ADDPROCESSDIALOG.ADD_OPPTY')}}
        h3(v-if="stageType.type=='CASE'") {{$t('ADDPROCESSDIALOG.ADD_CASE')}}
        v-btn.ml-3.add-deal-btn(v-if='stageDef.length <= 10' @click='addStageInput' width='40' height='40' :ripple='false' color='success' depressed dark fab rounded)
            v-icon(size='16') icon-add
    v-card-text(style="max-height: 1000px;" ref="content")
        v-form(ref='form')
            v-row.align-center.mt-1.mb-4(no-gutters)
                v-col(cols='12')
                    v-text-field#options.v-text-field__slot(v-model='stageType.stage_type_name' :label=`$t('ADDPROCESSDIALOG.NAME')`, :rules="formRules.nameRule" required="required")
                    v-select.edit-and-add-Progress.v-text-field__slot.height-48(v-model='stageType.type' :label=`$t('ADDPROCESSDIALOG.PAGE')`, :items='typeList' item-value='id' item-text='name'  height='48' flat dense  background-color='#F0F0F0')
            draggable(:list='stageDef' :move='checkMove')
                v-card.process-setting-item.pa-4(flat v-for='(item, index) in stageDef' :key='index')
                    v-row.align-center.justify-space-between.my-0(no-gutters)
                        v-col(cols='auto').d-flex.align-center.justify-start
                             h6.ma-0(v-if='$i18n.locale == "zh_TW"') 第 {{ index + 1 }} 個階段 
                             h6.ma-0(v-if='$i18n.locale == "zh_CN"') 第 {{ index + 1 }} 个阶段 
                             h6.ma-0(v-else) Step {{ index + 1 }}  
                        v-col(cols='1').d-flex.align-center.justify-center
                            v-btn(rounded icon @click='delStep(index)')
                                v-icon.v-btn__content.delBtn.icon(size='20' color='gray') icon-remove
                    v-row.align-center.my-0.mt-4(no-gutters)
                        v-col(cols='12')
                            v-text-field#options.v-text-field__slot.height-48(v-model='stageDef[index].stage_name' :label=`$t('ADDPROCESSDIALOG.STAGE_NAME')`, :rules="formRules.stepRule" validate-on-blur required="required")
                        v-col(cols='12')
                            v-select.edit-and-add-Progress.v-text-field__slot.height-48(v-model='stageDef[index].stage_color' :items='stageDefColorList' item-text='name' item-value='hexCode' solo dense :label=`$t('ADDPROCESSDIALOG.COLOR')`,)
                    v-row.align-center.my-0(v-if='stageDef[index].isMore' no-gutters)
                        v-col.pr-2(cols='6')
                            v-text-field#options.v-text-field__slot.height-48(v-model='stageDef[index].stage_probability' :label=`$t('ADDPROCESSDIALOG.PROBABILITY')`, :rules="formRules.probabilityRule" validate-on-blur required="required" suffix="%" v-if="stageType.type!='CASE'")
                        v-col.pl-2(cols='6')
                            v-text-field#options.v-text-field__slot.height-48(v-model='stageDef[index].zombie_day' :label=`$t('ADDPROCESSDIALOG.FINISH_DAY')`, :rules="formRules.zombieRule" validate-on-blur required="required", :suffix=`$t('ADDPROCESSDIALOG.DAY')` v-if="stageType.type!='CASE'")
                        v-col(cols='12')
                            v-text-field#options.v-text-field__slot.height-48(v-model='stageDef[index].zombie_day' :label=`$t('ADDPROCESSDIALOG.FINISH_DAY')`, :rules="formRules.zombieRule" validate-on-blur required="required", :suffix=`$t('ADDPROCESSDIALOG.DAY')` v-if="stageType.type=='CASE'")
                        v-col(cols='12')
                            v-textarea.v-text-field__slot.textarea-style(v-model='stageDef[index].guideline' :label=`$t('ADDPROCESSDIALOG.GUIDE')`,)   
                    v-row.align-center.my-0(no-gutters)
                        v-col(cols='12').d-flex.align-center.justify-end
                            v-btn.ml-0.pa-0(v-if='!stageDef[index].isMore' color='primary' text @click='more(index)') MORE                         
                            v-btn.ml-0.pa-0(v-if='stageDef[index].isMore' color='primary' text @click='less(index)') LESS                
            v-row.align-center.mt-1.mb-4(no-gutters v-if='stageDef.length == 0')
                v-col(cols='12')
                    h6.t-pink  {{$t('ADDPROCESSDIALOG.ERROR')}}
    v-card-actions(v-if='!isExceed')
        v-row(no-gutters)
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed  :disabled='addLoading')
                    v-progress-circular(v-if='addLoading' indeterminate size='20')
                    .t-white(v-else) {{$t('GENERAL.ADD')}}
    v-card-actions(v-else)
        v-row(no-gutters)
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed  :disabled='addLoading')
                    v-progress-circular(v-if='addLoading' indeterminate size='20')
                    .t-white(v-else) {{$t('GENERAL.ADD')}}
</template>

<script>
    import Vue from 'vue';
    import draggable from 'vuedraggable';
    import stageDefColorList from "@/array/the_stageDefColorList";
    import StageDataService from '@/services/StageDataService';
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        props: {
            stageType: {
                type: Object,
                required: true,
            }, 
            stageDef: {
                type: Array,
                required: true,
            },
            pageType:{
                type: String,
                required: true,
            }
        },
        components: {
            draggable
        },
        data() {
            return {
                valid: true,
                stageDefColorList: stageDefColorList,
                colorCode: '',
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_PROCESS'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                        ],
                    stepRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_STAGE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    probabilityRule: [(v) => /^([1-9][0-9]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')],
                    zombieRule: [(v) => /^([1-9][0-9]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')],
                },
                isExceed: false,
                typeList:[{id:"DEAL",name:i18n.t('DEAL.TITLE')},{id:"CASE",name:i18n.t('CASE.TITLE')}],
                addLoading:false,
            }
        },
        created() { 			
            this.stageDef[0].stage_color = this.stageDefColorList[0].hexCode;
            this.stageType.type = this.pageType
        },
        updated() {
            let h = this.$refs.content.clientHeight;            
            if (h > 850) {
                this.isExceed = true;
            } else {
                this.isExceed = false;
            }
        },
        methods: {
            validate() {
                this.$refs.form.validate();
            },
            validateForm(){
                if(this.$refs.form.validate() && this.stageDef.length > 0){
                    return true;
                }
                else{
                    return false;
                }
            },
            async addStageInput() {
                let amount = this.stageDef.length;
                if (amount < 11) {
                    this.stageDef.push({
                        sequence_number: '',
                        stage_name: '',
                        stage_probability: '',
                        zombie_day: '',
                        guideline: '',
                        stage_color: '',
                        isMore: false,
                    });
                }
                let r = amount % 6
                switch (r) {
                    case 0:
                        this.stageDef[amount].stage_color = this.stageDefColorList[0].hexCode;
                        break;
                    case 1:
                        this.stageDef[amount].stage_color = this.stageDefColorList[1].hexCode;
                        break;
                    case 2:
                        this.stageDef[amount].stage_color = this.stageDefColorList[2].hexCode;
                        break;
                    case 3:
                        this.stageDef[amount].stage_color = this.stageDefColorList[3].hexCode;
                        break;
                    case 4:
                        this.stageDef[amount].stage_color = this.stageDefColorList[4].hexCode;
                        break;
                    case 5:
                        this.stageDef[amount].stage_color = this.stageDefColorList[5].hexCode;
                        break;
                    default:
                        break;
                }
            },
            checkMove: function() {
                // window.console.log("Future index: " + e.draggedContext.futureIndex);
            },
            onConfirm() {
                if(this.validateForm()){
                    this.addLoading = true;
                    let i = 1;
                    this.stageDef.forEach(step => {
                        step.sequence_number = i;
                        if (step.stage_probability == "") {
                            step.stage_probability = null;
                        }
                        if (step.zombie_day == "") {
                            step.zombie_day = null;
                        }
                        if (step.guideline == "") {
                            step.guideline = null;
                        }
                        i++;
                    });

                    let processData = {
                        stage_type_name: this.stageType.stage_type_name,
                        stagedef_set: this.stageDef,
                        type:this.stageType.type,
                    }                    
                    StageDataService.create(processData)
                    .then(response => {
                        this.addLoading = false;
                        this.$emit('emitAddProcessDialog', true);
                        this.$emit('emitAddProcessData', response.data);
                    });
                }
            },
            onCancel() {
                this.$emit('emitAddProcessDialog', false);
            },
            more(index) {
                this.stageDef[index].isMore = true;
            },
            less(index) {
                this.stageDef[index].stage_probability = null;
                this.stageDef[index].zombie_day = null;
                this.stageDef[index].guideline = null;
                this.stageDef[index].isMore = false;
            },
            delStep(index) {
                this.stageDef.splice(index, 1);
            },
        }        
    });
</script>